@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	color: #001529;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	display: flex;
	background-color: #0f4557;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: #fcfcfc;
	padding: 50px 20px;
}

.App-body {
	display: flex;
	padding: 30px 40px;
	background-color: #fcfcfc;
}

.App-link {
	color: #61dafb;
}

.label {
	line-height: 100%;
	font-weight: 400;
	font-size: 1.2rem !important;
	margin: 8px 0 !important;
}

.symptom-btn {
	margin: 20px 0 !important;
	width: 160px;
	border: 2px solid transparent !important;
}

button {
	font-weight: 600 !important;
	border: 2px solid transparent;
}

button:hover {
	background-color: transparent !important;
	border: 1px solid #001529 !important;
	color: #eaf1f7 !important;
}

button:focus {
	background-color: transparent !important;
	border: 1px solid #001529 !important;
	color: #001529 !important;
}

.error {
	font-weight: bold;
	color: red !important;
}

.chart {
	justify-content: center;
	width: 100%;
	height: 280px;
}

#components-layout-demo-side .logo {
	height: 32px;
	margin: 16px;
	background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
	background: #fcfcfc;
}

.logo > a {
	color: #fcfcfc !important;
}

.logo {
	font-size: 1.5rem;
	font-weight: bold;
	margin: 15px 25px;
}

.logo > p {
	margin-bottom: 0;
}

.cursor-pointer {
	cursor: pointer;
}

.ant-layout {
	background-color: #fcfcfc !important;
}

.main-content {
	margin-left: 200px;
	max-width: 100vw;
	height: 100vh;
	overflow: 'initial' ;
}

.mobile-nav {
	display: none;
}

.loader {
	font-size: 42px;
	margin-top: 20%;
	margin-left: auto;
	margin-right: auto;
}

.img-loader {
	font-size: 20px;
	margin-left: 10%;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-responsive {
	display: flex;
	flex-direction: row;
}

.flex-column-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.align-left {
	align-items: flex-start;
}

.ant-btn-primary {
	color: #fcfcfc;
	background: #001529;
	border-color: #001529;
}

.news-card {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.travel-card {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	margin-top: 20px;
	padding: 10px;
}

.card-link {
	display: inline-block;
}

.card-content {
	display: flex;
	flex-direction: column;
}

.card-title {
	font-weight: 700;
	font-size: 1.1rem;
}

.card-date {
	color: #767676;
	font-size: 0.8rem;
	margin: 10px 0;
}

.card-body {
	font-size: 0.8rem;
}

.news-header {
	display: inline-block;
	margin: 30px 0;
	font-size: 1.3rem;
	font-weight: 600;
}

.symptom-header {
	display: inline-block;
	margin-bottom: 15px;
	font-size: 1rem;
	font-weight: 500;
}

.full-width {
	width: 100%;
}

.responsive-chart {
	box-sizing: border-box;
	padding: 20px;
	width: 100%;
	height: 350px;
	background-color: #fcfcfc;
}

.not-available {
	display: block;
	margin: 15px auto;
	font-size: 1rem;
	font-weight: 500;
}

.testing-btn {
	display: block;
	width: 230px;
	margin: 15px 0;
	border: 1px solid #6f7689 !important;
}

.testing-list {
	margin: 30px 0;
	width: auto;
}

.full-width-card {
	width: 100%;
}

.sub-heading {
	display: block;
	color: #6d7488;
	font-size: 0.8rem;
	margin: 15px 0 2px 0;
}

.heading {
	font-weight: 700;
	font-size: 1.5rem;
}

.sub-info {
	display: block;
	font-size: 1.2rem;
	font-weight: 700;
	margin-bottom: 15px;
	color: #001529 !important;
}

.sub-heading-row {
	display: block;
	justify-content: center;
	color: #6f7689;
	font-size: 0.8rem;
}

.sub-info-row {
	display: block;
	font-size: 1.2rem;
	font-weight: 700;
	margin: 0 10px 0 5px;
	color: #001529 !important;
}

.sub-info-small {
	display: block;
	font-size: 1rem;
	font-weight: 700;
	margin-bottom: 10px;
	color: #001529 !important;
}

.schedule {
	display: block;
	font-size: 0.9rem;
	font-weight: 600;
	margin-bottom: 5px;
	color: #001529 !important;
}

.ant-menu-item-selected {
	background-color: transparent !important;
	font-weight: 700;
	transition: all 50ms ease-in-out;
}

.ant-drawer-body {
	padding: 0 !important;
}

.details {
	font-size: 0.8rem !important;
	text-align: justify !important;
}

.collapse-header {
	margin-top: 20px;
}

.leaflet-popup-content {
	width: auto !important;
	margin: 20px !important;
}

.mobile-header {
	display: none;
}

.signup-btn {
	width: 130px !important;
	height: 40px !important;
	font-size: 1rem;
}

.social-signup-btn {
	width: 240px !important;
	height: 40px !important;
	margin-right: 20px;
	margin-top: 20px;
}

.ant-form-item-control {
	max-width: 250px !important;
}

.forgot-pass {
	padding: 5px 10px;
	margin-bottom: 20px;
}

.forgot-pass:hover {
	border: 1px solid #001529;
	color: #001529;
}

.forgot-pass:focus {
	border: 1px solid #001529;
	color: #001529;
}

.profile-img {
	border-radius: 100px;
	width: 100px;
}

.btn-right-margin {
	margin-top: 10px;
	margin-right: 20px;
}

.image-input {
	margin: 20px 0;
	padding-left: 15%;
}

.upload-btn {
	margin: 10px 0;
}

.card-body > ol > li {
	font-size: 0.9rem;
	margin: 10px 0;
}

.margin-bottom {
	margin-bottom: 30px;
}

.margin-top {
	margin-top: 30px;
}

.margin-small-bottom {
	margin-bottom: 10px !important;
}

.big-margin-top {
	margin-top: 60px;
}

.error-text {
	font-size: 0.9rem;
	margin: 20px 0;
	color: #ba1b1d !important;
}

.country-flag {
	max-width: 30%;
	align-self: center;
	margin: 20px 0;
}

.country-flag-2 {
	max-width: 30%;
	align-self: center;
	margin: 30px;
}

.inline-block {
	display: inline-block !important;
}

.inline {
	display: inline;
}

.block {
	display: block;
}

.align-center {
	align-items: center;
}

.margin-tb {
	margin: 20px 0 !important;
}

.small-text {
	display: inline;
	font-size: 1rem;
	margin: 15px 5px 2px 10px;
}

.space-between {
	justify-content: space-between;
}

.ant-statistic-title {
	color: #6d7488 !important;
}

button:disabled {
	background-color: transparent !important;
	border: 1px solid #6d7488 !important;
	color: #6d7488 !important;
}

/* COLORS */

.color-green {
	color: #3e673c !important;
}

.color-red {
	color: #ba1b1d !important;
}

.color-orange {
	color: #f76819 !important;
}

.color-blue {
	color: #255f85 !important;
}

.color-yellow {
	color: #ac9300 !important;
}

.color-primary {
	color: #001529 !important;
}

.color-link {
	color: #0054a3 !important;
}

.color-gray {
	color: #6d7488 !important;
}

/* Media for mobile */

@media only screen and (max-width: 768px) {
	.hide-mobile {
		display: none;
	}

	.mobile-header {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		height: 60px;
		width: 100vw;
		background-color: #001529;
		color: #fcfcfc;
		font-size: 1.3rem;
		font-weight: 800;
		letter-spacing: 0.4rem;
		z-index: 1;
	}

	.main-content {
		margin: 10px;
	}

	.mobile-nav {
		display: flex;
		position: relative;
	}

	.floating-menu {
		position: fixed;
		bottom: 0;
		right: 0;
		margin: 20px;
		z-index: 1;
		background-color: #001529;
	}

	.App-body {
		padding: 15px;
		margin-top: 60px;
	}

	.label {
		font-size: 1.1rem !important;
		margin: 12px 0 !important;
	}

	.symptom-btn {
		width: 180px !important;
	}

	.view-map-btn {
		width: auto !important;
		padding: 8px 20px !important;
		font-size: 1rem !important;
		font-weight: 600;
		height: auto !important;
		margin-top: 5px !important;
	}

	button {
		width: auto !important;
		height: 45px !important;
		font-size: 1rem !important;
	}

	.flex-responsive {
		display: flex;
		flex-direction: column;
	}

}
.center{
	margin-left:auto;
	margin-left:auto
}
p.chartName{
	font-size: x-large;
	Color:rgb(4, 4, 65)
}
p.chartTitle{
	font-size: xx-large;
	Color:rgb(4, 4, 65)
}
.ant-radio-checked .ant-radio-button .ant-radio-button-checked .ant-radio-inner {
	border-color: #0077d7;
  }
button.signOut{
	background-color:#001529;
	text-align: left;
	
	
}
button.signOut:hover{
	color:#001529
}